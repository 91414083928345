import { render, staticRenderFns } from "./approve.vue?vue&type=template&id=c2d8b6c4&scoped=true&"
import script from "./approve.vue?vue&type=script&lang=js&"
export * from "./approve.vue?vue&type=script&lang=js&"
import style0 from "./approve.vue?vue&type=style&index=0&id=c2d8b6c4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2d8b6c4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c2d8b6c4')) {
      api.createRecord('c2d8b6c4', component.options)
    } else {
      api.reload('c2d8b6c4', component.options)
    }
    module.hot.accept("./approve.vue?vue&type=template&id=c2d8b6c4&scoped=true&", function () {
      api.rerender('c2d8b6c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "found/views/process/components/process_approve/approve.vue"
export default component.exports